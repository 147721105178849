.disposal_balances_table{
  .transactions_columns {
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
    }
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_row {
      & > :first-child {
        width: 20%;
      }
      & > :nth-child(2) {
        width: 20%;
      }
      & > :nth-child(3) {
        width: 20%;
      }
      & > :last-child {
        width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
      }
    }
  }

}