@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext);
@font-face {
  font-family: "Akrobat Light";
  src: url(/static/media/Akrobat-Light.75a8c0f3.eot) format("eot");
  src: url(/static/media/Akrobat-Light.ee70a587.woff2) format("woff2"), url(/static/media/Akrobat-Light.74684ef6.woff) format("woff"), url(/static/media/Akrobat-Light.69c9774a.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat Regular";
  src: url(/static/media/Akrobat-Regular.2c142dcf.eot) format("eot");
  src: url(/static/media/Akrobat-Regular.648b2c6f.woff2) format("woff2"), url(/static/media/Akrobat-Regular.5b37ea1d.woff) format("woff"), url(/static/media/Akrobat-Regular.da3cb340.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat SemiBold";
  src: url(/static/media/Akrobat-SemiBold.00c747fe.eot) format("eot");
  src: url(/static/media/Akrobat-SemiBold.2142f350.woff2) format("woff2"), url(/static/media/Akrobat-SemiBold.b3190b7f.woff) format("woff"), url(/static/media/Akrobat-SemiBold.90597676.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat Bold";
  src: url(/static/media/Akrobat-Bold.8026a37f.eot) format("eot");
  src: url(/static/media/Akrobat-Bold.1089187a.woff2) format("woff2"), url(/static/media/Akrobat-Bold.598f6214.woff) format("woff"), url(/static/media/Akrobat-Bold.2f95fa5f.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat ExtraBold";
  src: url(/static/media/Akrobat-ExtraBold.7679e2b1.eot) format("eot");
  src: url(/static/media/Akrobat-ExtraBold.2f72c1d5.woff2) format("woff2"), url(/static/media/Akrobat-ExtraBold.9b8c9188.woff) format("woff"), url(/static/media/Akrobat-ExtraBold.2ff979cf.ttf) format("truetype"); }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

* {
  box-sizing: border-box; }

body {
  background-color: #EDF2F8;
  color: #424B5A;
  font-family: "Akrobat Regular", sans-serif; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.container {
  width: 100%;
  padding: 0 150px; }

@media screen and (max-width: 1410px) {
  .container {
    padding: 0 100px; } }

button {
  font-family: "Akrobat Regular", sans-serif; }

.flex {
  display: flex; }

.justify-content-between {
  justify-content: space-between; }

.align-items-center {
  align-items: center; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-center {
  display: flex;
  align-items: center; }

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .flex-center-btw .add_btn {
    margin-right: 0; }

.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

hr {
  height: 1px;
  background-color: #C2D1D9;
  margin: 30px 0; }

.dashboard_block {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 197px;
  width: 24.2%;
  display: inline-block;
  padding: 15px 15px 10px;
  margin-bottom: 30px; }
  .dashboard_block hr {
    margin: 0; }

.date_wrapper {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: flex;
  align-items: center; }
  .date_wrapper img {
    margin-right: 5px; }

.block_label {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px; }

.block_value {
  color: #334150;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; }

.block_info_item {
  margin-bottom: 10px; }

.app-back_link {
  margin: 48px 0;
  display: inline-block; }

.page_options_wrapper {
  width: 50%; }

.title-block h1 {
  color: #334150;
  font-size: 60px;
  line-height: 75px;
  margin-bottom: 30px;
  font-weight: 400; }

div .add_btn {
  color: #0253B3;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-transform: none;
  margin-right: -5px;
  padding: 0; }
  div .add_btn img {
    margin-left: 9px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
  div .add_btn:hover {
    background: none;
    text-decoration: underline; }
    div .add_btn:hover img {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

div .blue_btn {
  color: #0253B3;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  div .blue_btn:hover {
    text-decoration: underline;
    background: transparent; }

div .red_btn {
  color: #D0021B;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  div .red_btn:hover {
    text-decoration: underline;
    background: transparent; }

.item_page_block {
  padding: 40px 50px;
  margin-bottom: 60px;
  background: #fff; }

.indent_none {
  margin: 0 !important;
  padding: 0 !important; }

.app-back_link {
  cursor: pointer; }

h3 {
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 32px;
  font-weight: 100; }

.table_container .table_header .table_header {
  display: flex; }

.table_header .row_item {
  color: #7F8FA4;
  font-size: 12px; }

.table_body .row_item {
  color: #334150;
  font-size: 16px; }
  .table_body .row_item--plus, .table_body .row_item--minus {
    cursor: pointer; }
  .table_body .row_item--plus {
    margin-left: 10px; }

.table_body .table_row {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  text-decoration: none; }

.table_row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px; }

.title-block-page {
  padding: 50px 0 30px;
  align-items: flex-end; }
  .title-block-page .title-page h1 {
    font-weight: 400;
    color: #334150;
    font-size: 60px;
    line-height: 75px; }

.page_block {
  background: #fff;
  padding: 50px 50px 30px;
  margin-bottom: 65px; }

.red_text {
  color: #D0021B; }

.green_text {
  color: #2CC197; }

.dashboard-section_content {
  padding: 50px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

input {
  font-family: "Akrobat Regular", sans-serif; }
  input::-webkit-input-placeholder {
    font-family: "Akrobat Regular", sans-serif; }
  input::-moz-placeholder {
    font-family: "Akrobat Regular", sans-serif; }
  input::-ms-input-placeholder {
    font-family: "Akrobat Regular", sans-serif; }
  input::placeholder {
    font-family: "Akrobat Regular", sans-serif; }

.tab_custom {
  padding-bottom: 50px; }
  .tab_custom button {
    background: transparent;
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    margin-right: 30px;
    position: relative;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer; }
    .tab_custom button:hover {
      color: #334150; }
  .tab_custom .active {
    color: #334150;
    position: relative; }
    .tab_custom .active:before {
      position: absolute;
      content: "";
      width: 100%;
      background: #0253B3;
      height: 2px;
      left: 0;
      bottom: -10px; }

.gap-5 {
  grid-gap: 5px;
  gap: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.gap-10 {
  grid-gap: 10px;
  gap: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.gap-15 {
  grid-gap: 15px;
  gap: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.gap-20 {
  grid-gap: 20px;
  gap: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.gap-25 {
  grid-gap: 25px;
  gap: 25px; }

.mt-25 {
  margin-top: 25px; }

.mb-25 {
  margin-bottom: 25px; }

.gap-30 {
  grid-gap: 30px;
  gap: 30px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.gap-35 {
  grid-gap: 35px;
  gap: 35px; }

.mt-35 {
  margin-top: 35px; }

.mb-35 {
  margin-bottom: 35px; }

.gap-40 {
  grid-gap: 40px;
  gap: 40px; }

.mt-40 {
  margin-top: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.gap-45 {
  grid-gap: 45px;
  gap: 45px; }

.mt-45 {
  margin-top: 45px; }

.mb-45 {
  margin-bottom: 45px; }

.gap-50 {
  grid-gap: 50px;
  gap: 50px; }

.mt-50 {
  margin-top: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.gap-55 {
  grid-gap: 55px;
  gap: 55px; }

.mt-55 {
  margin-top: 55px; }

.mb-55 {
  margin-bottom: 55px; }

.gap-60 {
  grid-gap: 60px;
  gap: 60px; }

.mt-60 {
  margin-top: 60px; }

.mb-60 {
  margin-bottom: 60px; }

.gap-65 {
  grid-gap: 65px;
  gap: 65px; }

.mt-65 {
  margin-top: 65px; }

.mb-65 {
  margin-bottom: 65px; }

:root {
  --color-constants-blue-lightest: #DAECFF;
  --color-constants-blue-light: #b6daff;
  --color-constants-blue-normal: #0072E5;
  --color-constants-blue-dark: #0253B3;
  --color-constants-blue-darkest: #003A75;
  --color-constants-grey-lightest: #6e7781;
  --color-constants-grey-light: #57606a;
  --color-constants-grey-normal: #424a53;
  --color-constants-grey-dark: #32383f;
  --color-constants-grey-darkest: #24292f; }

.burger-button-default {
  width: 20px;
  height: 17px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  .burger-button-default span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  .burger-button-default span:nth-child(1) {
    top: 0; }
  .burger-button-default span:nth-child(2), .burger-button-default span:nth-child(3) {
    top: 5px; }
  .burger-button-default span:nth-child(4) {
    top: 10px; }
  .burger-button-default_open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%; }
  .burger-button-default_open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  .burger-button-default_open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .burger-button-default_open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%; }
  .burger-button-default_sidebar {
    position: absolute;
    top: 35px;
    right: 35px; }

.app-head {
  background-color: #01397C; }
  .app-head a {
    text-decoration: none; }

.header-wrapper {
  height: 80px; }

.app-nav {
  margin-left: 80px; }
  .app-nav a {
    font-size: 16px; }

.nav_link {
  opacity: 0.5;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 20px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .nav_link:hover {
    opacity: 1; }
  .nav_link.active_nav_link {
    opacity: 1; }

.app-head_options > :first-child {
  margin-right: 10px; }

.app-head_options .exit_btn {
  opacity: 0.5;
  color: #FFFFFF;
  font-family: "Akrobat Light", sans-serif;
  font-size: 14px;
  line-height: 13px;
  font-weight: 300;
  text-transform: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .app-head_options .exit_btn:hover {
    opacity: 1; }

.app-head_options .notification_btn {
  padding: 6px; }
  .app-head_options .notification_btn svg {
    fill: #ffffff;
    font-size: 28px; }

.notification_list hr {
  margin: 0; }

.notification_item {
  padding: 10px 0;
  cursor: pointer; }
  .notification_item .flex-center {
    margin-bottom: 5px; }
    .notification_item .flex-center > span, .notification_item .flex-center > img {
      margin-right: 10px; }

.notification_star {
  position: relative;
  cursor: default;
  display: inline-block;
  color: #f5a623;
  font-size: 18px; }

.notification_title {
  color: #A7ADB3;
  font-family: "Akrobat Light", sans-serif;
  font-size: 14px;
  line-height: 17px; }
  .notification_title span {
    color: #334150;
    font-family: "Akrobat SemiBold", sans-serif;
    font-weight: 600; }

.notification_date {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px; }

.sidebar_wrapper .sidebar_paper {
  background-color: #01397C;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .sidebar_wrapper .sidebar_paper p {
    width: 250px;
    color: #ffffff;
    font-size: 25px;
    margin-bottom: 40px;
    position: relative;
    cursor: pointer; }
    .sidebar_wrapper .sidebar_paper p .active {
      opacity: 1; }

.line_side_bar {
  margin-bottom: 45px;
  background: #FFFFFF;
  height: 2px;
  opacity: 0.5; }

.linkClass {
  opacity: 0.5;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
  color: #FFFFFF; }
  .linkClass:hover {
    opacity: 1; }

.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%; }
  .loader_wrapper > div {
    color: #0253B3; }

.no-items {
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: 40px 0;
  align-items: center;
  margin: 30px 0 50px; }
  .no-items .item-block {
    text-align: center; }
    .no-items .item-block .box-img {
      padding-bottom: 20px; }
    .no-items .item-block .title {
      color: #334150;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 10px; }
    .no-items .item-block .descriptions {
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px; }

.default_dialog_root .paper_custom_dialog {
  min-width: 840px;
  width: 100%; }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  background: transparent; }

.default_dialog_root .title-dialog {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 30px; }

.default_dialog_root .descriptions {
  color: #334150;
  font-size: 16px;
  line-height: 20px; }

.default_dialog_root .btn-wrapper {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #C2D1D9;
  display: flex;
  align-items: center;
  width: 100%; }
  .default_dialog_root .btn-wrapper button, .default_dialog_root .btn-wrapper a {
    margin-right: 14px; }

.dialog-form {
  width: 100%; }

.dialog-field {
  display: flex; }
  .dialog-field .custom_input_wrapper {
    margin-right: 50px; }
  .dialog-field .check_field_wrapper {
    margin-right: 30px; }

.dialog-field-change {
  display: flex;
  flex-flow: column; }
  .dialog-field-change .custom_input_wrapper {
    margin-bottom: 20px;
    position: relative; }
    .dialog-field-change .custom_input_wrapper i {
      position: absolute;
      right: 0;
      top: 0; }

.dialog-field-representation .custom_input_wrapper {
  max-width: 100%; }

.representation-block {
  padding-top: 20px;
  display: flex; }
  .representation-block .schedule {
    display: flex;
    flex-flow: column;
    justify-content: space-between; }
    .representation-block .schedule .title {
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px; }
    .representation-block .schedule .schedule-day {
      display: flex;
      align-items: center; }
      .representation-block .schedule .schedule-day p {
        padding: 0 5px; }
      .representation-block .schedule .schedule-day .check_field_wrapper {
        padding-right: 15px;
        width: 55px; }

.block-change-info {
  width: 100%; }
  .block-change-info .text-info {
    display: flex;
    align-items: center;
    color: #334150;
    font-size: 16px;
    line-height: 20px; }
    .block-change-info .text-info img {
      margin-right: 7px; }

.dialog_wrapper {
  width: 100%;
  padding: 40px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #FFFFFF; }
  .dialog_wrapper .reviews {
    padding: 0 !important; }

.no_padding_bottom {
  padding-bottom: 0 !important; }

.page-animation-enter {
  opacity: 0.01; }

.page-animation-enter.page-animation-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.page-animation-leave {
  opacity: 1; }

.page-animation-leave.page-animation-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.page-animation-appear {
  opacity: 0.01; }

.page-animation-appear.page-animation-appear-active {
  opacity: 1;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }

.default_button_wrapper .default_button {
  text-align: center;
  display: inline-flex;
  font-family: "Akrobat SemiBold", sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 147px;
  padding: 0 10px;
  border-radius: 3px;
  box-shadow: none;
  white-space: nowrap;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .default_button_wrapper .default_button:disabled {
    opacity: 0.7; }
  .default_button_wrapper .default_button.default_button_contained {
    background-color: #0253B3;
    color: #ffffff;
    border: 1px solid #0253B3; }
  .default_button_wrapper .default_button.default_button_contained_green {
    background-color: #16D761;
    color: #ffffff;
    border: 1px solid #16D761; }
  .default_button_wrapper .default_button.default_button_outlined {
    color: #ffffff;
    border: 1px solid #ffffff; }
    .default_button_wrapper .default_button.default_button_outlined.cancel_btn {
      border: 1px solid #7F8FA4;
      color: #7F8FA4; }
    .default_button_wrapper .default_button.default_button_outlined.delete_btn {
      border: 1px solid #D0021B;
      color: #D0021B; }
    .default_button_wrapper .default_button.default_button_outlined.waiting_for_documents {
      border: 1px solid #0253B3;
      color: #0253B3; }
    .default_button_wrapper .default_button.default_button_outlined.waiting_for_details {
      border: 1px solid #249AF3;
      color: #249AF3; }
  .default_button_wrapper .default_button.default_button_reject {
    border: 1px solid #D0021B;
    background-color: #D0021B; }
    .default_button_wrapper .default_button.default_button_reject:hover {
      background-color: #bf061b; }
  .default_button_wrapper .default_button div {
    color: #ffffff; }

.default_button_wrapper .full_btn_width {
  width: 100%; }

.default_button_wrapper--last_btn {
  margin-left: auto;
  margin-right: 0; }

.popper_wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  padding: 5px 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  margin-top: 5px;
  max-height: 300px !important;
  overflow: auto; }
  .popper_wrapper.products_popper {
    padding: 5px 0; }

.orders-main-block .flex-center-btw .flex-center-start .title-block {
  margin-top: 50px; }

.orders-main-block .flex-center-btw .add_button {
  margin-top: 50px;
  font-size: 16px; }

.orders-main-block .dashboard-section_content {
  margin: 0 0 135px;
  padding: 40px 50px 10px 50px;
  background: #fff; }
  .orders-main-block .dashboard-section_content h1 {
    height: 40px;
    width: 105px;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px; }
  .orders-main-block .dashboard-section_content .transactions_columns {
    width: 100%; }
    .orders-main-block .dashboard-section_content .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .orders-main-block .dashboard-section_content .transactions_columns .table_row > :first-child {
      width: 30%; }
    .orders-main-block .dashboard-section_content .transactions_columns .table_row > :nth-child(2) {
      width: 30%; }
    .orders-main-block .dashboard-section_content .transactions_columns .table_row > :nth-child(3) {
      width: 30%; }
    .orders-main-block .dashboard-section_content .transactions_columns .table_row > :last-child {
      width: 10%;
      justify-content: flex-end;
      display: flex; }
    .orders-main-block .dashboard-section_content .transactions_columns .table_row .delete_btn {
      cursor: pointer;
      background: transparent;
      font-family: "Akrobat Regular", sans-serif;
      font-size: 16px;
      color: #D0021B; }
      .orders-main-block .dashboard-section_content .transactions_columns .table_row .delete_btn:hover {
        opacity: 0.7; }
  .orders-main-block .dashboard-section_content .descriptions {
    margin: 0 15% 0 15%; }

.dialog-delete-wrapper .title {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 30px; }

.dialog-delete-wrapper .descriptions {
  color: #334150;
  font-size: 16px; }

.dialog-delete-wrapper .search_wrapper {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #C2D1D9;
  height: 40px;
  width: 100%;
  display: flex; }
  .dialog-delete-wrapper .search_wrapper.search_wrapper_small {
    width: 471px;
    justify-content: space-between; }
    .dialog-delete-wrapper .search_wrapper.search_wrapper_small > div:first-child {
      width: calc(100% - 34px); }
    .dialog-delete-wrapper .search_wrapper.search_wrapper_small .search_input_icon {
      display: flex;
      align-items: center;
      width: 34px; }
  .dialog-delete-wrapper .search_wrapper input {
    width: calc(100% - 34px);
    height: 38px;
    border-radius: 3px;
    line-height: 38px;
    padding: 0 12px;
    font-size: 14px;
    font-family: "Akrobat Regular", sans-serif; }
    .dialog-delete-wrapper .search_wrapper input::-webkit-input-placeholder {
      color: #C2D1D9; }
    .dialog-delete-wrapper .search_wrapper input::-moz-placeholder {
      color: #C2D1D9; }
    .dialog-delete-wrapper .search_wrapper input::-ms-input-placeholder {
      color: #C2D1D9; }
    .dialog-delete-wrapper .search_wrapper input::placeholder {
      color: #C2D1D9; }
  .dialog-delete-wrapper .search_wrapper button {
    padding: 5px 7px;
    border-radius: 0; }
    .dialog-delete-wrapper .search_wrapper button svg {
      font-size: 20px;
      fill: #C2D1D9; }

.dialog-button-wrapper {
  border-top: 1px solid #C2D1D9;
  padding-top: 30px;
  margin-top: 28px;
  display: flex; }
  .dialog-button-wrapper button {
    margin-right: 15px; }

.blacklist-dialog {
  z-index: 0 !important; }

.blacklist_ul {
  width: 471px; }
  .blacklist_ul li {
    box-sizing: border-box;
    border-bottom: 1px solid #C2D1D9;
    padding: 15px 20px !important;
    background: #fff !important;
    cursor: pointer;
    width: 100%;
    text-align: left; }
    .blacklist_ul li:hover {
      background-color: #DEEBFF !important;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
  .blacklist_ul > :last-child {
    border-bottom: none; }

.MuiAutocomplete-noOptions {
  font-family: "Akrobat Regular", sans-serif !important; }

#products_popper-popup .MuiAutocomplete-option[data-focus="true"] {
  background-color: #DEEBFF !important; }

#products_popper-popup li {
  font-family: "Akrobat Regular", sans-serif; }
  #products_popper-popup li:hover {
    background-color: #DEEBFF !important; }

.snack_root {
  margin-bottom: 20px; }
  .snack_root > div {
    padding: 5px 12px;
    border-radius: 5px;
    color: #334150;
    font-size: 16px;
    font-weight: 600;
    font-family: "Akrobat SemiBold", sans-serif;
    min-width: 400px; }
    .snack_root > div > :first-child {
      padding: 10px 0 10px; }
      .snack_root > div > :first-child span img {
        margin-right: 8px; }
    .snack_root > div button {
      padding: 5px; }
  .snack_root.error_snack div {
    background-color: #ff223b;
    font-family: "Inter", sans-serif;
    color: #ffffff;
    text-align: center; }
    .snack_root.error_snack div svg {
      fill: #ffffff; }
  .snack_root.success_snack > div {
    background-color: #ffffff; }
    .snack_root.success_snack > div svg {
      fill: #C2D1D9; }

.select_component {
  height: 100%; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8; }
    .select_component .select__control--is-focused .indicator_active svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__control {
    height: 100%;
    border-radius: 3px;
    border: 1px solid #C2D1D9;
    font-size: 14px; }
    .select_component .select__control:hover {
      border: 1px solid #C2D1D9; }
    .select_component .select__control .select__placeholder {
      color: rgba(127, 143, 164, 0.5); }
    .select_component .select__control .select__single-value {
      color: #7F8FA4; }
    .select_component .select__control .select__input {
      color: #7F8FA4;
      font-size: 14px; }
      .select_component .select__control .select__input input {
        font-family: "Akrobat Regular", sans-serif !important;
        font-size: 12px; }
    .select_component .select__control .select__indicator-separator {
      background: none; }
    .select_component .select__control .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center; }
      .select_component .select__control .select_indicator svg {
        fill: #7F8FA4;
        font-size: 20px; }
    .select_component .select__control .select__loading-indicator span {
      background-color: #7F8FA4; }
    .select_component .select__control .select_close_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer; }
      .select_component .select__control .select_close_btn svg {
        fill: #7F8FA4;
        font-size: 14px; }
  .select_component .select__menu {
    color: #7F8FA4;
    z-index: 9999; }
    .select_component .select__menu .select__menu-list {
      font-family: "Akrobat Regular", sans-serif;
      font-size: 14px; }

.checkbox-date {
  color: #7f8fa4;
  font-family: "Akrobat Light", sans-serif;
  font-size: 14px;
  margin-left: 4px;
  opacity: 0.7; }

.checkbox-img {
  margin-left: 12px; }

.main-container-block {
  margin-top: 50px; }
  .main-container-block .dashboard-section_content {
    display: block;
    padding-top: 0; }
    .main-container-block .dashboard-section_content:after {
      content: "";
      display: table;
      clear: both; }
    .main-container-block .dashboard-section_content .price-item,
    .main-container-block .dashboard-section_content .review-item {
      position: relative;
      float: left;
      margin-right: 2%;
      width: 23.5%;
      height: 265px; }
      .main-container-block .dashboard-section_content .price-item:nth-child(4n),
      .main-container-block .dashboard-section_content .review-item:nth-child(4n) {
        margin-right: 0; }
      .main-container-block .dashboard-section_content .price-item .block_value_extra,
      .main-container-block .dashboard-section_content .review-item .block_value_extra {
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis; }

.price_list_page .markup_wrapper {
  display: flex; }

.price_list_page .expansion-title {
  position: relative; }
  .price_list_page .expansion-title .checkbox_custom {
    height: 40px;
    align-items: center;
    display: flex; }
  .price_list_page .expansion-title .markup_wrapper {
    position: absolute;
    left: 50%; }

.price_list_page .MuiTouchRipple-root-44 {
  display: none !important; }

.content-block {
  background: #fff;
  padding: 40px 50px;
  margin-bottom: 55px; }
  .content-block h3 {
    padding-bottom: 14px; }
  .content-block .no-items {
    padding-top: 0; }
  .content-block.content-block-reviews {
    margin-bottom: 30px; }
  .content-block .scroll_block {
    max-height: 400px;
    overflow: auto; }
    .content-block .scroll_block::-webkit-scrollbar {
      width: 4px; }
    .content-block .scroll_block::-webkit-scrollbar-track {
      border-radius: 2px;
      margin-right: 5px;
      background: #edf2f8; }
    .content-block .scroll_block::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #01397c; }
    .content-block .scroll_block::-webkit-scrollbar-thumb:window-inactive {
      background: #01397c; }

.info-time {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .info-time img {
    margin-right: 5px; }
  .info-time span {
    color: #7f8fa4;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px; }

.reviews-wrapper .name {
  color: #01397c;
  font-size: 23px;
  font-weight: 600;
  line-height: 29px; }

.rating {
  display: flex;
  align-items: center;
  padding-bottom: 15px; }
  .rating span {
    color: #7f8fa4;
    font-size: 14px;
    padding-left: 5px; }

.reviews:last-of-type {
  border: none;
  padding: 30px 0 40px; }

.reviews {
  padding: 30px 0 30px;
  border-bottom: 1px solid #c2d1d9; }
  .reviews .rating_wrapper {
    margin-bottom: 10px; }
  .reviews .descriptions {
    color: #334150;
    font-size: 14px;
    line-height: 17px;
    max-width: 650px;
    padding: 0 0 15px; }
    .reviews .descriptions:last-of-type {
      padding-bottom: 0;
      word-break: break-all; }
    .reviews .descriptions span {
      font-family: "Akrobat SemiBold", sans-serif; }
  .reviews textarea {
    font-family: "Akrobat Regular", sans-serif;
    resize: none;
    box-sizing: border-box;
    height: 60px;
    font-size: 14px;
    max-width: 650px;
    width: 100%;
    border: 1px solid #c2d1d9;
    border-radius: 3px;
    padding: 12px 11px;
    margin-bottom: 10px; }
    .reviews textarea::-webkit-input-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300; }
    .reviews textarea::-moz-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300; }
    .reviews textarea::-ms-input-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300; }
    .reviews textarea::placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300; }
  .reviews .default_button_wrapper .default_button.default_button_contained {
    background-color: #0253b3;
    color: #ffffff;
    border: none; }

.markup_wrapper {
  position: relative;
  height: 40px;
  display: none;
  align-items: center; }
  .markup_wrapper input {
    border: 1px solid #c2d1d9;
    box-sizing: border-box;
    height: 31px;
    width: 56px;
    border-radius: 3px;
    color: #334150;
    padding: 0 22px 0 12px;
    font-size: 14px;
    font-family: "Akrobat Regular", sans-serif; }
    .markup_wrapper input::-webkit-input-placeholder {
      color: #7f8fa4; }
    .markup_wrapper input::-moz-placeholder {
      color: #7f8fa4; }
    .markup_wrapper input::-ms-input-placeholder {
      color: #7f8fa4; }
    .markup_wrapper input::placeholder {
      color: #7f8fa4; }
  .markup_wrapper > span {
    color: #7f8fa4;
    font-family: "Akrobat Light", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    padding-right: 16px; }
  .markup_wrapper .box_icon {
    color: #7f8fa4;
    position: absolute;
    right: 12px;
    font-size: 14px;
    font-family: "Akrobat Light", sans-serif;
    font-weight: 300; }
  .markup_wrapper p {
    position: absolute;
    top: calc(50% - 10px);
    left: 130px;
    color: red;
    width: 250px; }

.check_field_wrapper {
  color: transparent !important;
  display: flex !important; }
  .check_field_wrapper label {
    margin: 0 !important; }
    .check_field_wrapper label > :first-child > :last-child > :first-child {
      width: 17px !important;
      height: 17px !important;
      top: 1px !important;
      left: 1px !important; }
    .check_field_wrapper label > :last-child {
      color: #334150 !important;
      font-size: 14px !important;
      margin-left: 9px !important;
      font-weight: 400 !important;
      font-family: "Akrobat Regular", sans-serif !important; }
  .check_field_wrapper .custom_check {
    padding: 0 !important; }

.expansion-title {
  color: transparent;
  display: flex; }
  .expansion-title label {
    margin: 0; }
    .expansion-title label > :first-child > :last-child > :first-child {
      width: 17px !important;
      height: 17px !important;
      top: 1px !important;
      left: 1px !important; }
    .expansion-title label > :last-child {
      color: #334150;
      font-size: 14px;
      margin-left: 5px;
      font-weight: 400;
      font-family: "Akrobat Regular", sans-serif; }
  .expansion-title .custom_check {
    padding: 0; }
  .expansion-title .approve .custom_check {
    display: none; }

.expansion-block {
  display: flex !important;
  flex-direction: column; }
  .expansion-block button {
    padding: 5px; }
    .expansion-block button svg {
      font-size: 18px; }

.schedule_map_wrapper {
  display: flex;
  flex-direction: column; }
  .schedule_map_wrapper > div {
    height: 27px;
    display: flex;
    align-items: center; }
  .schedule_map_wrapper > label {
    margin: 5px 0 !important; }

.expansion-body {
  padding-left: 27px; }

.search_wrapper {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #C2D1D9;
  height: 40px;
  width: 100%;
  display: flex; }
  .search_wrapper.search_wrapper_small {
    width: 275px; }
  .search_wrapper input {
    width: calc(100% - 34px);
    height: 38px;
    line-height: 38px;
    padding: 0 12px;
    font-size: 14px;
    font-family: "Akrobat Regular", sans-serif; }
    .search_wrapper input::-webkit-input-placeholder {
      color: #C2D1D9; }
    .search_wrapper input::-moz-placeholder {
      color: #C2D1D9; }
    .search_wrapper input::-ms-input-placeholder {
      color: #C2D1D9; }
    .search_wrapper input::placeholder {
      color: #C2D1D9; }
  .search_wrapper button {
    padding: 5px 7px;
    border-radius: 0; }
    .search_wrapper button svg {
      font-size: 20px;
      fill: #C2D1D9; }

.orders-main-block > .title-block h1 {
  margin-top: 50px;
  margin-bottom: 30px; }

.orders-main-block > .dashboard-section_content .wrapper-upload p {
  width: 213px;
  color: #334150;
  font-size: 32px; }

.orders-main-block > .dashboard-section_content .wrapper-upload .form-control {
  margin: 20px 0; }
  .orders-main-block > .dashboard-section_content .wrapper-upload .form-control .form-input--file {
    position: relative;
    overflow: hidden;
    padding-right: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    width: 301px;
    height: 41px;
    line-height: 41px;
    padding-left: 12px;
    border: 1px solid #C2D1D9;
    border-radius: 3px; }
    .orders-main-block > .dashboard-section_content .wrapper-upload .form-control .form-input--file .form-input-file {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0; }
    .orders-main-block > .dashboard-section_content .wrapper-upload .form-control .form-input--file .form-input--file-button {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: -1px;
      background-color: #0253B3;
      color: #fff;
      height: 100%;
      line-height: 41px;
      width: 98px;
      text-align: center;
      border-radius: 0 3px 3px 0; }

.orders-main-block > .dashboard-section_content > .content-block {
  padding: 0; }

.orders-main-block > hr {
  background: red !important; }

.orders-main-block > .dashboard-section_content.dashboard-import-orders {
  flex-direction: column;
  margin: 0; }

.import_order_page .drop_down_block {
  display: flex;
  flex-flow: column; }
  .import_order_page .drop_down_block > span {
    color: #7F8FA4;
    font-family: "Akrobat", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    padding-bottom: 4px; }
  .import_order_page .drop_down_block .select_wrapper {
    width: 301px; }
    .import_order_page .drop_down_block .select_wrapper > .select_component .select__menu .select__menu-list {
      max-height: 300px;
      overflow: auto; }
      .import_order_page .drop_down_block .select_wrapper > .select_component .select__menu .select__menu-list::-webkit-scrollbar {
        width: 4px; }
      .import_order_page .drop_down_block .select_wrapper > .select_component .select__menu .select__menu-list::-webkit-scrollbar-track {
        border-radius: 2px;
        margin-right: 5px;
        background: #EDF2F8; }
      .import_order_page .drop_down_block .select_wrapper > .select_component .select__menu .select__menu-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #01397C; }
      .import_order_page .drop_down_block .select_wrapper > .select_component .select__menu .select__menu-list::-webkit-scrollbar-thumb:window-inactive {
        background: #01397C; }

.import_order_page .form-input--file-text {
  color: #7F8FA4;
  font-weight: 300; }

.import_order_page h3 {
  padding-bottom: 10px; }

.import_order_page .form-input--file-button {
  text-transform: uppercase; }

.import_order_page .expansion-title {
  position: relative; }
  .import_order_page .expansion-title .checkbox_custom {
    height: 30px;
    align-items: center;
    display: flex; }

.import_order_page .MuiTouchRipple-root-44 {
  display: none !important; }

.edit_btn_wrapper {
  display: flex; }
  .edit_btn_wrapper .error_after_button {
    padding: 0 10px;
    line-height: 40px;
    color: red;
    font-family: "Akrobat", sans-serif; }
  .edit_btn_wrapper .success_after_button {
    padding: 0 10px;
    line-height: 40px;
    color: green;
    font-family: "Akrobat", sans-serif; }
  .edit_btn_wrapper .loader_wrapper > div {
    width: 30px !important;
    height: 30px !important; }

.import-orders-dialog > .title {
  height: 40px;
  width: 731px;
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 18px; }

.test > label > span {
  color: blue !important; }

.waiting-company {
  display: flex;
  flex-direction: column;
  padding: 5px 0; }
  .waiting-company .item-content {
    height: 30px;
    display: flex;
    flex-flow: row wrap;
    max-width: 60%; }
  .waiting-company button {
    padding: 5px; }
    .waiting-company button svg {
      font-size: 18px; }
  .waiting-company .name-company {
    color: #334150;
    font-size: 14px;
    font-weight: 400;
    font-family: "Akrobat Regular", sans-serif;
    cursor: pointer; }
  .waiting-company__btn {
    order: 999;
    margin-left: auto;
    background-color: #0253B3;
    color: #fff;
    border-radius: 3px;
    height: 30px;
    padding: 8px 10px !important;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .waiting-company__btn:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0; }
  .loader_wrapper > div {
    color: #0253B3; }

.message_popper .message_tooltip {
  background-color: #29323C;
  font-size: 10px;
  word-break: break-word;
  font-family: "Akrobat Regular", sans-serif; }

.message_popper .error_message_tooltip {
  background-color: #ff0000;
  font-size: 10px;
  font-family: "Akrobat Regular", sans-serif; }

body .auth_tooltip_popper {
  top: 8px !important;
  left: 9px; }

.item_page .title_items {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 20px; }

.item_page .descriptions_items {
  padding-bottom: 7px;
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px; }

.item_page .text {
  padding-bottom: 23px;
  color: #334150;
  font-size: 16px;
  line-height: 18px; }

.item_page .title_items_mini {
  display: flex;
  color: #334150;
  font-size: 23px;
  line-height: 29px;
  padding-bottom: 20px; }

.item_page .select_wrapper {
  width: 301px; }

.item_page .status_wrapper .title_items_mini span {
  margin-right: 5px; }

.item_page .btn_wrapper_product {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex; }
  .item_page .btn_wrapper_product .default_button_wrapper {
    margin-right: 15px; }
  .item_page .btn_wrapper_product .file_error {
    cursor: pointer;
    color: red; }

.line_wrapper {
  background: #C2D1D9;
  width: 100%;
  height: 1px;
  margin: 30px 0; }

.half_block_wrapper_items {
  display: flex;
  align-items: center; }
  .half_block_wrapper_items > div:first-of-type {
    width: 30%; }
  .half_block_wrapper_items > div:last-of-type {
    width: 70%; }

.indent_no {
  margin: 0 !important;
  padding: 0 !important; }

.info-social-wrapper {
  padding-left: 20px; }
  .info-social-wrapper img {
    padding-right: 8px; }

.descriptions_dialog_component {
  margin-top: 30px;
  color: #334150;
  font-size: 16px;
  line-height: 20px; }

.file_add {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer; }
  .file_add span {
    padding-left: 7px;
    color: #0253B3;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px; }

.file_add:last-of-type {
  padding: 0; }

.item_page > .item_page_block:last-of-type {
  margin-bottom: 60px; }

.item_page > .item_page_block:first-of-type {
  margin-bottom: 30px; }

.refund_requests_page_block .transactions_table .transactions_columns {
  width: 100%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_body .table_row > :nth-child(3) {
    font-weight: 600; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :first-child {
    width: 25%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :nth-child(2) {
    width: 25%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :nth-child(3) {
    width: 25%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :last-child {
    width: 25%; }

.wrapper_btn_items {
  display: flex; }
  .wrapper_btn_items .default_button_wrapper {
    margin-right: 15px; }

.descriptions_dialog_component {
  color: #334150;
  font-size: 16px;
  line-height: 20px; }

.block_status_info > span.text {
  padding-bottom: 0; }

textarea {
  font-family: "Akrobat Regular", sans-serif;
  resize: none;
  box-sizing: border-box;
  min-height: 100px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #c2d1d9;
  border-radius: 3px;
  padding: 12px 11px; }
  textarea::-webkit-input-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea::-moz-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea::-ms-input-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea::placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea:hover {
    border-color: var(--color-constants-blue-light); }
  textarea:focus {
    border-color: var(--color-constants-blue-light);
    box-shadow: 0 0 0 3px var(--color-constants-blue-lightest); }
  textarea:focus-visible {
    outline: 0; }

.comment-component .comment-component__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px; }

.comment-component .comment-component__body {
  margin-top: 10px; }

.comment-component .comment-component-header__title {
  color: var(--color-constants-blue-dark); }

.download-file_error {
  cursor: pointer;
  margin-left: 15px; }
  .download-file_error svg {
    fill: #ff0000; }

.custom_input_wrapper {
  width: 100%;
  max-width: 300px; }
  .custom_input_wrapper .custom_input_label {
    font-family: "Akrobat Regular", sans-serif;
    color: #C2D1D9;
    font-size: 14px;
    -webkit-transform: translate(11px, 13px) scale(1);
            transform: translate(11px, 13px) scale(1);
    font-weight: 400; }
    .custom_input_wrapper .custom_input_label.custom_input_label_focused {
      color: rgba(255, 255, 255, 0.72); }
    .custom_input_wrapper .custom_input_label.custom_input_label_active {
      color: #01397C;
      -webkit-transform: translate(13px, -6px) scale(0.75);
              transform: translate(13px, -6px) scale(0.75); }
    .custom_input_wrapper .custom_input_label.custom_input_label_error {
      color: #ff0000; }
  .custom_input_wrapper .custom_input {
    font-family: "Akrobat Regular", sans-serif;
    background-color: #FFFFFF;
    color: #01397C;
    font-size: 14px;
    border-radius: 4px;
    max-height: 40px; }
    .custom_input_wrapper .custom_input:hover .custom_input_outline {
      border-color: #C2D1D9 !important; }
    .custom_input_wrapper .custom_input:focus {
      background-color: #FFFFFF; }
    .custom_input_wrapper .custom_input input {
      padding: 12px 14px; }
    .custom_input_wrapper .custom_input .custom_input_outline {
      border-color: #C2D1D9; }
    .custom_input_wrapper .custom_input.custom_input_focused {
      background-color: #FFFFFF; }
      .custom_input_wrapper .custom_input.custom_input_focused .custom_input_outline {
        border: 1px solid #01397C; }
    .custom_input_wrapper .custom_input.custom_input_error .custom_input_outline, .custom_input_wrapper .custom_input.custom_input_error:hover .custom_input_outline {
      border-color: #ff0000 !important; }
    .custom_input_wrapper .custom_input.custom_input_disabled {
      color: #334150; }
  .custom_input_wrapper .custom_input_adorned_end {
    padding-right: 5px; }
    .custom_input_wrapper .custom_input_adorned_end > div:last-of-type {
      margin-right: 8px; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type span {
        color: #C2D1D9;
        right: 12px;
        position: absolute; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type span.error_empty {
          right: 0;
          width: 100%;
          height: 15px;
          top: -10px; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type svg {
        fill: #ff0000;
        cursor: pointer; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn {
        padding: 0;
        width: 25px;
        height: 25px; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn:hover {
          background-color: transparent; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn svg {
          fill: #C2D1D9; }
  .custom_input_wrapper .custom_input_adorned_start > div:first-of-type {
    margin-right: 0; }
    .custom_input_wrapper .custom_input_adorned_start > div:first-of-type span {
      color: #C2D1D9; }

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 0 0;
  background-color: transparent; }

.pagination-list {
  display: flex; }
  .pagination-list li {
    border-radius: 2px;
    width: 28px;
    height: 28px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    -webkit-transition: all ease .3s;
    transition: all ease .3s; }
    .pagination-list li:hover {
      border: 1px solid #0253B3; }
    .pagination-list li a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .pagination-list li img {
      margin-top: -3px;
      opacity: 0.54; }

.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #0253B3;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 28px;
  width: 28px;
  font-size: 14px;
  min-width: 28px; }
  .pagination-link.pagination-link-active {
    border-radius: 2px;
    background-color: #0253B3;
    box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff; }
    .pagination-link.pagination-link-active:hover {
      opacity: 0.7; }

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  .pagination-ellipsis a {
    cursor: pointer;
    background: transparent;
    height: 28px;
    width: 28px;
    color: #0253B3;
    text-decoration: none;
    text-align: center;
    line-height: 28px; }

.panel_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }
  .panel_page .ml20px {
    margin-left: 20px; }
  .panel_page .search_transactions_panel {
    width: 471px; }
    .panel_page .search_transactions_panel .search_product_wrapper {
      width: 100%; }
    .panel_page .search_transactions_panel .search_input_icon {
      display: flex;
      align-items: center;
      padding-right: 12px;
      cursor: pointer; }

.select_wrapper {
  width: 201px;
  height: 41px; }
  .select_wrapper .select__menu .select__menu-list {
    padding: 0 8px !important; }
    .select_wrapper .select__menu .select__menu-list > div {
      color: #7f8fa4;
      box-sizing: border-box;
      border-bottom: 1px solid #c2d1d9;
      padding: 15px 0px !important;
      background: #fff !important;
      cursor: pointer; }
      .select_wrapper .select__menu .select__menu-list > div:hover {
        background: #fff !important; }
    .select_wrapper .select__menu .select__menu-list > div:last-of-type {
      border-bottom: none; }

.select__value-container:hover {
  cursor: text; }

.select__indicators:hover {
  cursor: pointer; }

.orders_page .select_wrapper {
  width: 171px; }

.orders_page .panel_page .search_transactions_panel {
  width: 390px; }

.orders_page .control_panel {
  display: flex;
  padding: 30px 0 0 0; }
  .orders_page .control_panel--page-size {
    display: flex;
    margin-left: auto; }

.orders_page .pagination-container {
  padding: 0; }

.orders_page .radio-wrapper input[type="radio"] {
  display: none; }

.orders_page .radio-wrapper input[type="radio"]:checked + label {
  border-radius: 2px;
  background-color: #0253B3;
  box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.08);
  color: #ffffff; }
  .orders_page .radio-wrapper input[type="radio"]:checked + label:hover {
    opacity: 0.7; }

.orders_page .radio-wrapper label {
  border-radius: 2px;
  width: 28px;
  height: 28px;
  min-width: 28px;
  font-size: 14px;
  background: transparent;
  color: #0253B3;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  cursor: pointer;
  -webkit-transition: all ease .3s;
  transition: all ease .3s; }
  .orders_page .radio-wrapper label:hover {
    border: 1px solid #0253B3; }

.orders_table_wrapper .transactions_table .table_row {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  padding-left: 5px; }

.orders_table_wrapper .transactions_table .transactions_columns {
  width: 100%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_body .table_row > :nth-child(2) {
    font-weight: 600; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_body .table_row > :nth-child(8) {
    font-weight: 600; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_header .row_item {
    width: 100%;
    display: flex;
    align-items: center;
    color: #7F8FA4 !important;
    margin-right: 5px; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_body .row_item {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :first-child {
    width: 8%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(2) {
    width: 5%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(3) {
    width: 8%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(4) {
    width: 6%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(5) {
    width: 8%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(6) {
    width: 8%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(7) {
    width: 4%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(8) {
    width: 4%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(9) {
    width: 5%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(10) {
    width: 4%;
    color: #D0021B; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(11) {
    width: 5%;
    text-align: center; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(12) {
    width: 8%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(13) {
    width: 5%;
    margin-right: 5px; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(14) {
    width: 5%; }
  .orders_table_wrapper .transactions_table .transactions_columns .table_row > :last-child {
    width: 11%; }
    .orders_table_wrapper .transactions_table .transactions_columns .table_row > :last-child .select_wrapper {
      width: 130px; }

.orders_table_wrapper .amount {
  width: 100%; }
  .orders_table_wrapper .amount input {
    width: 100%; }

.block_status_info {
  display: flex;
  align-items: center; }
  .block_status_info .text {
    color: #334150;
    font-size: 16px;
    padding-left: 5px; }
  .block_status_info .status {
    border-radius: 50%;
    height: 6px;
    width: 6px; }

.ru_value {
  font-family: Roboto, sans-serif;
  font-weight: 500; }

.link-orders:hover {
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
  background-color: #EDF2F8; }

.transactions_table_wrapper .transactions_table .table_row {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top: none; }

.transactions_table_wrapper .transactions_table .transactions_columns {
  width: 100%; }
  .transactions_table_wrapper .transactions_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .transactions_table_wrapper .transactions_table .transactions_columns .table_row > :first-child {
    width: 21%; }
  .transactions_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(2) {
    width: 21%; }
  .transactions_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(3) {
    width: 21%; }
  .transactions_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(4) {
    width: 21%; }
  .transactions_table_wrapper .transactions_table .transactions_columns .table_row > :last-child {
    width: 16%; }

.panel_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }
  .panel_page .search_transactions_panel {
    width: 471px; }
    .panel_page .search_transactions_panel .search_product_wrapper {
      width: 100%; }
    .panel_page .search_transactions_panel .search_transactions_input_icon {
      display: flex;
      align-items: center;
      padding-right: 12px; }

.select_wrapper {
  width: 201px;
  height: 41px; }
  .select_wrapper .select__menu .select__menu-list {
    padding: 0 8px !important; }
    .select_wrapper .select__menu .select__menu-list > div {
      color: #7F8FA4;
      box-sizing: border-box;
      border-bottom: 1px solid #C2D1D9;
      padding: 15px 0px !important;
      background: #fff !important;
      cursor: pointer; }
      .select_wrapper .select__menu .select__menu-list > div:hover {
        background: #fff !important; }
    .select_wrapper .select__menu .select__menu-list > div:last-of-type {
      border-bottom: none; }

.select__value-container:hover {
  cursor: pointer; }

.select__indicators:hover {
  cursor: pointer; }

.search_transactions_list {
  width: 427px; }
  .search_transactions_list .search_transactions_item {
    box-sizing: border-box;
    border-bottom: 1px solid #C2D1D9;
    padding: 15px 0px !important;
    background: #fff !important;
    cursor: pointer; }
  .search_transactions_list > :last-child {
    border-bottom: none; }
  .search_transactions_list .search_transactions_no_items {
    box-sizing: border-box;
    padding: 15px 0px !important;
    background: #fff !important; }

.amount-info-wrapper {
  padding-top: 37px;
  display: flex; }
  .amount-info-wrapper input {
    box-sizing: border-box;
    height: 41px;
    width: 301px;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    font-family: "Akrobat Regular", sans-serif;
    padding: 0 30px 0 12px;
    font-size: 14px;
    font-weight: 300;
    color: #334150;
    position: relative; }
    .amount-info-wrapper input::-webkit-input-placeholder {
      color: #7F8FA4; }
    .amount-info-wrapper input::-moz-placeholder {
      color: #7F8FA4; }
    .amount-info-wrapper input::-ms-input-placeholder {
      color: #7F8FA4; }
    .amount-info-wrapper input::placeholder {
      color: #7F8FA4; }
  .amount-info-wrapper span {
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    margin-left: -20px;
    z-index: 111;
    align-items: center;
    justify-content: center;
    display: flex; }

.transactions-dialog {
  z-index: 0 !important; }

.transactions_ul {
  width: 410px; }
  .transactions_ul .search_transactions_item {
    box-sizing: border-box;
    border-bottom: 1px solid #C2D1D9;
    padding: 15px 0px !important;
    background: #fff !important;
    cursor: pointer; }
  .transactions_ul > :last-child {
    border-bottom: none; }
  .transactions_ul .search_transactions_no_items {
    box-sizing: border-box;
    padding: 15px 0px !important;
    background: #fff !important; }

.refund_requests_table_wrapper .transactions_table .table_row {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top: none; }

.refund_requests_table_wrapper .transactions_table .transactions_columns {
  width: 100%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :first-child {
    width: 30%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(2) {
    width: 15%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(3) {
    width: 15%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(4) {
    width: 17%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(5) {
    width: 17%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(6) {
    width: 16%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(7) {
    width: 17%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :nth-child(8) {
    width: 10%; }
  .refund_requests_table_wrapper .transactions_table .transactions_columns .table_row > :last-child {
    width: 16%; }

.refund_requests_table_wrapper .control_panel {
  display: flex;
  padding: 30px 0 0 0; }

.control_panel--page-size {
  display: flex;
  margin-left: auto; }
  .control_panel--page-size .radio-wrapper input[type="radio"] {
    display: none; }
  .control_panel--page-size .radio-wrapper input[type="radio"]:checked + label {
    border-radius: 2px;
    background-color: #0253B3;
    box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff; }
    .control_panel--page-size .radio-wrapper input[type="radio"]:checked + label:hover {
      opacity: 0.7; }
  .control_panel--page-size .radio-wrapper label {
    border-radius: 2px;
    width: 28px;
    height: 28px;
    min-width: 28px;
    font-size: 14px;
    background: transparent;
    color: #0253B3;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    cursor: pointer;
    -webkit-transition: all ease .3s;
    transition: all ease .3s; }
    .control_panel--page-size .radio-wrapper label:hover {
      border: 1px solid #0253B3; }

.mexcar-auto {
  display: inline;
  color: #0253b3;
  cursor: pointer; }

.download-file {
  display: flex;
  align-items: center;
  margin-bottom: 41px; }
  .download-file .download {
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    box-sizing: border-box;
    border: 1px solid #c2d1d9;
    border-radius: 3px 0 0 3px;
    height: 41px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    min-width: 301px;
    cursor: default; }
  .download-file .default_button_wrapper {
    margin-left: -98px;
    cursor: pointer; }
  .download-file .default_button_wrapper .default_button {
    border-radius: 0 3px 3px 0;
    height: 41px;
    min-width: 98px; }
  .download-file .download-file_error {
    cursor: pointer;
    margin-left: 15px; }
    .download-file .download-file_error svg {
      fill: #ff0000; }

.download_file {
  position: relative; }
  .download_file .text-info {
    color: #334150;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 30px; }
  .download_file #file {
    display: none; }
  .download_file .select_wrapper {
    width: 301px;
    margin-right: 49px; }
    .download_file .select_wrapper .select__value-container {
      height: 100%; }
    .download_file .select_wrapper .select__single-value {
      color: #334150; }
    .download_file .select_wrapper .select__menu .select__option--is-selected {
      color: #334150 !important; }
    .download_file .select_wrapper .select__menu .select__menu-list {
      padding: 8px 8px !important; }
    .download_file .select_wrapper .select__menu .select__menu-list > div {
      color: #7f8fa4;
      box-sizing: border-box;
      border-bottom: none;
      padding: 10px 10px !important;
      background: #fff !important;
      cursor: pointer; }
    .download_file .select_wrapper .select__menu .select__option:hover {
      background-color: #deebff !important;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }

.reception_history_block {
  margin-bottom: 60px; }
  .reception_history_block .reception_history {
    background: #FFFFFF;
    padding: 40px 50px 30px; }
    .reception_history_block .reception_history .title {
      color: #334150;
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 15px; }
    .reception_history_block .reception_history .transactions_columns {
      width: 100%; }
      .reception_history_block .reception_history .transactions_columns .table_header .row_item {
        display: flex;
        align-items: center; }
      .reception_history_block .reception_history .transactions_columns .table_row > :first-child {
        width: 45%; }
      .reception_history_block .reception_history .transactions_columns .table_row > :nth-child(2) {
        width: 35%; }
      .reception_history_block .reception_history .transactions_columns .table_row > :last-child {
        width: 20%;
        font-weight: 600; }
      .reception_history_block .reception_history .transactions_columns > :last-child {
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 35px; }
  .reception_history_block .pagination-container {
    padding-top: 0;
    margin-bottom: 5px; }

.product_selection .descriptions {
  color: #334150;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 20px; }

.product_selection .search_block {
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
  box-sizing: border-box; }
  .product_selection .search_block .select__value-container {
    height: 100% !important; }
  .product_selection .search_block input {
    box-sizing: border-box;
    height: 45px;
    width: 471px;
    border: 1px solid #c2d1d9;
    border-radius: 3px;
    padding: 0 40px 0 12px;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px; }
  .product_selection .search_block button {
    cursor: pointer;
    background: transparent;
    position: absolute;
    right: 12px;
    top: calc(50% - 9px); }

.product_selection .custom-input {
  display: flex;
  align-items: flex-end; }
  .product_selection .custom-input input {
    width: 266px; }

.product_selection .btn_wrapper_product {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex; }
  .product_selection .btn_wrapper_product .default_button_wrapper {
    margin-right: 15px; }
  .product_selection .btn_wrapper_product:last-child {
    margin-left: auto;
    margin-right: 0; }

.product_selection .product_wrapper_table {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px; }
  .product_selection .product_wrapper_table .line_v {
    width: 1px;
    margin: 0 30px;
    background: rgba(0, 0, 0, 0.1); }
  .product_selection .product_wrapper_table .title_table {
    color: #334150;
    font-size: 23px;
    line-height: 29px;
    padding-bottom: 10px; }
  .product_selection .product_wrapper_table .no_items {
    color: #7f8fa4;
    font-size: 16px;
    line-height: 20px;
    padding-top: 20px; }

.product_selection .table_left {
  width: 48%; }
  .product_selection .table_left .transactions_columns {
    width: 100%; }
    .product_selection .table_left .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .product_selection .table_left .transactions_columns .table_row > :first-child {
      width: 20%; }
    .product_selection .table_left .transactions_columns .table_row > :nth-child(2) {
      width: 35%; }
    .product_selection .table_left .transactions_columns .table_row > :nth-child(3) {
      width: 35%; }
    .product_selection .table_left .transactions_columns .table_row > :last-child {
      width: 10%; }

.product_selection .table_right {
  width: 48%; }
  .product_selection .table_right .transactions_columns {
    width: 100%; }
    .product_selection .table_right .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .product_selection .table_right .transactions_columns .table_row > :first-child {
      width: 20%; }
    .product_selection .table_right .transactions_columns .table_row > :nth-child(2) {
      width: 30%; }
    .product_selection .table_right .transactions_columns .table_row > :nth-child(3) {
      width: 22%; }
    .product_selection .table_right .transactions_columns .table_row > :nth-child(4) {
      width: 15%; }
    .product_selection .table_right .transactions_columns .table_row > :last-child {
      width: 13%;
      display: flex;
      justify-content: flex-end; }
      .product_selection .table_right .transactions_columns .table_row > :last-child button {
        cursor: pointer;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center; }

.table_body_scroll {
  overflow: auto;
  max-height: 550px; }
  .table_body_scroll::-webkit-scrollbar {
    width: 4px; }
  .table_body_scroll::-webkit-scrollbar-track {
    border-radius: 2px;
    margin-right: 5px;
    background: #edf2f8; }
  .table_body_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #01397c; }
  .table_body_scroll::-webkit-scrollbar-thumb:window-inactive {
    background: #01397c; }

.table_dialog_items {
  margin-top: 15px; }
  .table_dialog_items .transactions_columns {
    width: 100%; }
    .table_dialog_items .transactions_columns .table_row {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
      cursor: pointer;
      -webkit-transition: all ease 0.5s;
      transition: all ease 0.5s; }
      .table_dialog_items .transactions_columns .table_row:hover {
        background-color: #edf2f8; }
      .table_dialog_items .transactions_columns .table_row .name_items {
        color: #01397c;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; }
      .table_dialog_items .transactions_columns .table_row .block_table {
        display: flex;
        flex-flow: column; }
        .table_dialog_items .transactions_columns .table_row .block_table .title_table {
          color: #7f8fa4;
          font-size: 12px;
          font-weight: 300;
          line-height: 15px; }
        .table_dialog_items .transactions_columns .table_row .block_table .info_item {
          min-height: 17px;
          color: #334150;
          font-size: 14px;
          line-height: 17px; }
    .table_dialog_items .transactions_columns .table_row:last-of-type {
      border: none; }
    .table_dialog_items .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .table_dialog_items .transactions_columns .table_row > :first-child {
      width: 20%; }
    .table_dialog_items .transactions_columns .table_row > :nth-child(2) {
      width: 20%; }
    .table_dialog_items .transactions_columns .table_row > :nth-child(3) {
      width: 15%; }
    .table_dialog_items .transactions_columns .table_row > :nth-child(4) {
      width: 17%; }
    .table_dialog_items .transactions_columns .table_row > :nth-child(5) {
      width: 15%; }
    .table_dialog_items .transactions_columns .table_row > :last-child {
      display: flex;
      flex-flow: row; }

.product_wrapper {
  border: 1px solid #c2d1d9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px; }
  .product_wrapper .table_dialog_items {
    margin: 0; }
    .product_wrapper .table_dialog_items .table_row {
      height: auto; }
      .product_wrapper .table_dialog_items .table_row:hover {
        background: none; }
      .product_wrapper .table_dialog_items .table_row .name_items {
        color: #334150;
        font-size: 23px; }

.amount_wrapper {
  padding-top: 20px; }
  .amount_wrapper span {
    color: #7f8fa4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px; }

.amount {
  border: 1px solid #c2d1d9;
  border-radius: 3px;
  height: 41px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px; }
  .amount input {
    color: #334150;
    width: 30px;
    height: 100%;
    text-align: center; }
  .amount button {
    cursor: pointer;
    background: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    width: 30px;
    position: relative;
    font-size: 30px;
    padding-left: 9px; }

.no_display {
  display: none; }

@media print {
  .to-print-sticker {
    font-family: 'Arial';
    width: 58mm;
    height: 40mm;
    font-size: 12px;
    background-color: #FFFFFF; }
    .to-print-sticker__logo {
      width: 100%;
      height: 15mm; }
    .to-print-sticker__main-info {
      position: relative;
      display: flex;
      justify-content: flex-start;
      height: 100%;
      padding: 0.5mm 3mm 1mm; }
    .to-print-sticker__left-block {
      display: flex;
      flex-direction: column;
      max-width: 33mm;
      width: auto;
      justify-content: flex-start; }
      .to-print-sticker__left-block-up {
        height: 8mm; }
      .to-print-sticker__left-block-up--article {
        font-weight: 600; }
      .to-print-sticker__left-block-up--comment {
        text-overflow: clip;
        white-space: nowrap;
        overflow: hidden; }
    .to-print-sticker__left-block-bottom {
      width: 100%;
      margin-left: auto;
      margin-top: 3mm;
      height: 12mm; }
      .to-print-sticker__left-block-bottom svg {
        width: 100%; }
    .to-print-sticker__left-block-bottom--wo-comment {
      margin-top: 2mm; }
    .to-print-sticker__center-block {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: auto;
      margin-left: 2mm; }
      .to-print-sticker__center-block-up {
        height: 8mm; }
      .to-print-sticker__center-block-up--amount {
        font-size: 24px;
        font-weight: 600; }
        .to-print-sticker__center-block-up--amount span {
          font-size: 12px; }
    .to-print-sticker__center-block-bottom {
      font-size: 13px;
      letter-spacing: -1px;
      margin-top: 2.5mm;
      height: 12mm; }
      .to-print-sticker__center-block-bottom p {
        line-height: 5mm; }
        .to-print-sticker__center-block-bottom p:nth-child(1) {
          line-height: 4mm; }
        .to-print-sticker__center-block-bottom p:nth-child(2) {
          line-height: 4mm; }
    .to-print-sticker__center-block-bottom--wo-comment {
      margin-top: 1.5mm; }
    .to-print-sticker__right-block {
      height: 25mm;
      letter-spacing: -0.7px;
      text-align: center;
      margin-left: 3mm;
      font-size: 12px;
      font-weight: 600;
      -webkit-writing-mode: vertical-lr;
              writing-mode: vertical-lr; } }

.submit_order_error {
  cursor: pointer; }
  .submit_order_error svg {
    fill: #ff0000; }

.summary_table .title_table {
  color: #334150;
  font-size: 23px;
  line-height: 29px;
  padding-bottom: 10px; }

.summary_table .transactions_columns {
  width: 100%; }
  .summary_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .summary_table .transactions_columns .table_row > :first-child {
    width: 21%; }
  .summary_table .transactions_columns .table_row > :nth-child(2) {
    width: 21%; }
  .summary_table .transactions_columns .table_row > :nth-child(3) {
    width: 21%; }
  .summary_table .transactions_columns .table_row > :nth-child(4) {
    width: 21%; }
  .summary_table .transactions_columns .table_row > :last-child {
    width: 16%; }

.summary_auto .transactions_columns {
  width: 100%; }
  .summary_auto .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .summary_auto .transactions_columns .table_row > :first-child {
    width: 16%; }
  .summary_auto .transactions_columns .table_row > :nth-child(2) {
    width: 16%; }
  .summary_auto .transactions_columns .table_row > :nth-child(3) {
    width: 16%; }
  .summary_auto .transactions_columns .table_row > :nth-child(4) {
    width: 16%; }
  .summary_auto .transactions_columns .table_row > :nth-child(5) {
    width: 16%; }
  .summary_auto .transactions_columns .table_row > :last-child {
    width: 16%; }

.summary_page .btn_wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex;
  justify-content: space-between; }
  .summary_page .btn_wrapper > div {
    display: flex; }
  .summary_page .btn_wrapper .default_button_wrapper {
    margin-right: 15px; }

.summary_page .info_summary {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 0 10px; }
  .summary_page .info_summary div {
    font-weight: 600;
    padding-bottom: 10px; }
    .summary_page .info_summary div span {
      font-size: 16px; }

.print_btn {
  border: 1px solid #0253b3;
  height: 41px;
  width: 147px;
  background: transparent;
  cursor: pointer;
  border-radius: 3px;
  color: #0253b3;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  position: relative; }
  .print_btn:before {
    position: absolute;
    left: 15px;
    top: calc(50% - 8px);
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAAAXNSR0IArs4c6QAAAWdJREFUOBGVUr9Lw1AQvnsGHCKOulvBRXBz6FDo0Io/Oki7OOgg4uZWZ/MH6OSfkLEBhQRB6+DkrqtVR8GlFIRYa3JeIi9cmohphrzv7r778u7LIYhHNV0SYRoihAh4OYvT+/1ObZAuAhjjCUTsstpNJg+0REQHAxqWZnav6x/22rvkZIWA7gOncSpJGqumVwWgFd8fuYtHV5Xe+cYwqWlQ5ESEJ0Ds881WX9+CM9mjZPAfDpyt9cqyOcfjXxDgoeRnRtPFqZaX8Smq3VnVutFyH4lgW3Oj80+hPMNl4zg25Jd59qSuEB+SQIBQYNlrcHMNAF/YyGfBAc4fy1jgrsDMgxL/yYV4NIVkfzsNSy4kG1uXDXk44rBfVkhwkuMRmubO7Xxeo84NR58m30KH8ZkRCona/pffTrEKBCkhVGqvQE8uJSUUdDbtXFaBZCzEW1pWLW/icSJ9dqocvX+F4hUAXoPJH235D8IVdob6r8kqAAAAAElFTkSuQmCC) no-repeat;
    width: 18px;
    height: 16px; }
  .print_btn:hover {
    opacity: 0.8; }

.print_goods .transactions_columns {
  width: 100%; }
  .print_goods .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .print_goods .transactions_columns .table_row > :first-child {
    width: 21%; }
  .print_goods .transactions_columns .table_row > :nth-child(2) {
    width: 21%; }
  .print_goods .transactions_columns .table_row > :nth-child(3) {
    width: 21%; }
  .print_goods .transactions_columns .table_row > :nth-child(4) {
    width: 21%; }
  .print_goods .transactions_columns .table_row > :last-child {
    width: 16%; }

.print_goods_auto .transactions_columns {
  width: 100%; }
  .print_goods_auto .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .print_goods_auto .transactions_columns .table_row > :first-child {
    width: 16%; }
  .print_goods_auto .transactions_columns .table_row > :nth-child(2) {
    width: 16%; }
  .print_goods_auto .transactions_columns .table_row > :nth-child(3) {
    width: 16%; }
  .print_goods_auto .transactions_columns .table_row > :nth-child(4) {
    width: 16%; }
  .print_goods_auto .transactions_columns .table_row > :nth-child(5) {
    width: 16%; }
  .print_goods_auto .transactions_columns .table_row > :last-child {
    width: 16%; }

.no_margin {
  margin-top: 0px !important; }

.waitnig_reception_block {
  margin-bottom: 60px; }
  .waitnig_reception_block .waitnig_reception {
    background: #FFFFFF;
    padding: 40px 50px 30px; }
    .waitnig_reception_block .waitnig_reception .title {
      color: #334150;
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 15px; }
    .waitnig_reception_block .waitnig_reception .transactions_columns {
      width: 100%; }
      .waitnig_reception_block .waitnig_reception .transactions_columns .table_header .row_item {
        display: flex;
        align-items: center; }
      .waitnig_reception_block .waitnig_reception .transactions_columns .table_body .table_row {
        cursor: pointer; }
        .waitnig_reception_block .waitnig_reception .transactions_columns .table_body .table_row:hover {
          background-color: #DEEBFF;
          -webkit-transition: all 0.3s linear;
          transition: all 0.3s linear; }
      .waitnig_reception_block .waitnig_reception .transactions_columns .table_row > :first-child {
        width: 45%; }
      .waitnig_reception_block .waitnig_reception .transactions_columns .table_row > :nth-child(2) {
        width: 35%; }
      .waitnig_reception_block .waitnig_reception .transactions_columns .table_row > :last-child {
        width: 20%;
        font-weight: 600; }
      .waitnig_reception_block .waitnig_reception .transactions_columns > :last-child {
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 35px; }
  .waitnig_reception_block .pagination-container {
    padding-top: 0;
    margin-bottom: 5px; }

.price-add {
  margin-bottom: 30px;
  background: #fff;
  padding: 40px 50px; }

.tab-button {
  margin-bottom: 40px; }
  .tab-button button {
    margin-right: 50px;
    font-size: 32px;
    line-height: 40px;
    color: #C2D1D9;
    cursor: default;
    background: transparent;
    font-family: "Akrobat Regular", sans-serif; }
  .tab-button .active {
    color: #334150; }

.btn_wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex;
  justify-content: space-between; }
  .btn_wrapper > div {
    display: flex; }
  .btn_wrapper .default_button_wrapper {
    margin-right: 15px; }

.info_summary {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 0 10px; }
  .info_summary div {
    padding-bottom: 10px; }
    .info_summary div span {
      font-size: 16px; }

.print_btn {
  border: 1px solid #0253B3;
  height: 41px;
  width: 147px;
  background: transparent;
  cursor: pointer;
  border-radius: 3px;
  color: #0253B3;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  position: relative; }
  .print_btn:before {
    position: absolute;
    left: 15px;
    top: calc(50% - 8px);
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAAAXNSR0IArs4c6QAAAWdJREFUOBGVUr9Lw1AQvnsGHCKOulvBRXBz6FDo0Io/Oki7OOgg4uZWZ/MH6OSfkLEBhQRB6+DkrqtVR8GlFIRYa3JeIi9cmohphrzv7r778u7LIYhHNV0SYRoihAh4OYvT+/1ObZAuAhjjCUTsstpNJg+0REQHAxqWZnav6x/22rvkZIWA7gOncSpJGqumVwWgFd8fuYtHV5Xe+cYwqWlQ5ESEJ0Ds881WX9+CM9mjZPAfDpyt9cqyOcfjXxDgoeRnRtPFqZaX8Smq3VnVutFyH4lgW3Oj80+hPMNl4zg25Jd59qSuEB+SQIBQYNlrcHMNAF/YyGfBAc4fy1jgrsDMgxL/yYV4NIVkfzsNSy4kG1uXDXk44rBfVkhwkuMRmubO7Xxeo84NR58m30KH8ZkRCona/pffTrEKBCkhVGqvQE8uJSUUdDbtXFaBZCzEW1pWLW/icSJ9dqocvX+F4hUAXoPJH235D8IVdob6r8kqAAAAAElFTkSuQmCC) no-repeat;
    width: 18px;
    height: 16px; }
  .print_btn:hover {
    opacity: 0.8; }

.disposal_balances_table .transactions_columns {
  width: 100%; }
  .disposal_balances_table .transactions_columns .table_row {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-top: none; }
  .disposal_balances_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .disposal_balances_table .transactions_columns .table_row > :first-child {
    width: 20%; }
  .disposal_balances_table .transactions_columns .table_row > :nth-child(2) {
    width: 20%; }
  .disposal_balances_table .transactions_columns .table_row > :nth-child(3) {
    width: 20%; }
  .disposal_balances_table .transactions_columns .table_row > :last-child {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px; }

.new_issue .page_block .title {
  color: #334150;
  font-size: 32px;
  line-height: 40px; }

.new_issue .line_v {
  width: 100%;
  height: 1px;
  margin: 30px 0px;
  background: rgba(0, 0, 0, 0.3); }

.new_issue .search_block {
  position: relative;
  display: inline-block;
  margin-top: 30px; }
  .new_issue .search_block input {
    box-sizing: border-box;
    height: 41px;
    width: 471px;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    padding: 0 40px 0 12px;
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px; }
    .new_issue .search_block input::-webkit-input-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px; }
    .new_issue .search_block input::-moz-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px; }
    .new_issue .search_block input::-ms-input-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px; }
    .new_issue .search_block input::placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px; }
  .new_issue .search_block button {
    cursor: pointer;
    background: transparent;
    position: absolute;
    right: 12px;
    top: calc(50% - 9px); }

.new_issue .transactions_columns {
  width: 100%; }
  .new_issue .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .new_issue .transactions_columns .table_row > :first-child {
    width: 24%;
    padding-right: 6px; }
  .new_issue .transactions_columns .table_row > :nth-child(2) {
    width: 15%; }
  .new_issue .transactions_columns .table_row > :nth-child(3) {
    width: 15%; }
  .new_issue .transactions_columns .table_row > :nth-child(4) {
    width: 12%; }
  .new_issue .transactions_columns .table_row > :nth-child(5) {
    width: 17%; }
  .new_issue .transactions_columns .table_row > :last-child {
    width: 17%; }

.new_issue .table_container__summary {
  float: right;
  font-size: 16px;
  width: 17%;
  color: #334150;
  text-align: start; }

.new_issue .info_new_issue {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 12px;
  box-sizing: border-box;
  height: 59px;
  width: 651px;
  border: 1px solid #C2D1D9;
  border-radius: 3px;
  color: #334150;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  resize: none;
  font-family: "Akrobat Regular", sans-serif; }
  .new_issue .info_new_issue::-webkit-input-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7F8FA4; }
  .new_issue .info_new_issue::-moz-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7F8FA4; }
  .new_issue .info_new_issue::-ms-input-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7F8FA4; }
  .new_issue .info_new_issue::placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7F8FA4; }

.new_issue .btn_wrapper {
  justify-content: space-between;
  display: flex; }

.new_issue .to-print-sticker {
  width: 58mm;
  height: 60mm;
  max-height: 400px;
  background-color: #FFFFFF; }
  .new_issue .to-print-sticker > :first-child {
    margin-top: 40px;
    font-size: 23px;
    font-weight: 600;
    align-items: center; }
    .new_issue .to-print-sticker > :first-child div img {
      margin-right: 25px; }
  .new_issue .to-print-sticker > :nth-child(2) {
    margin-top: 31px; }
    .new_issue .to-print-sticker > :nth-child(2) > :first-child {
      display: flex;
      flex-flow: column;
      color: #334150; }
      .new_issue .to-print-sticker > :nth-child(2) > :first-child > :first-child {
        font-size: 23px; }
      .new_issue .to-print-sticker > :nth-child(2) > :first-child > :nth-child(2) {
        font-size: 32px;
        font-weight: bold; }
      .new_issue .to-print-sticker > :nth-child(2) > :first-child > :last-child {
        font-size: 23px; }
    .new_issue .to-print-sticker > :nth-child(2) > :last-child {
      display: flex;
      align-items: center;
      flex-flow: row;
      font-size: 23px;
      font-weight: bold; }
      .new_issue .to-print-sticker > :nth-child(2) > :last-child h1 {
        font-size: 60px; }
  .new_issue .to-print-sticker > :nth-child(3) {
    margin: 16px 0; }
  .new_issue .to-print-sticker > :last-child {
    margin-bottom: 40px; }
    .new_issue .to-print-sticker > :last-child > :first-child {
      justify-content: center;
      font-size: 23px;
      display: flex;
      flex-flow: column;
      color: #334150; }
  .new_issue .to-print-sticker .to-print-line {
    display: flex;
    justify-content: space-between; }
  .new_issue .to-print-sticker .comment {
    font-size: 16px;
    line-height: 20px;
    width: 90%; }

.more-dialog-btn {
  justify-content: space-between;
  display: flex; }
  .more-dialog-btn > div {
    display: flex; }

.print_goods {
  justify-content: space-between; }
  .print_goods .title {
    display: none; }
  .print_goods .transactions_columns {
    width: 100%; }
    .print_goods .transactions_columns .table_header .table_row > :first-child {
      width: 24%; }
    .print_goods .transactions_columns .table_header .table_row > :nth-child(2) {
      width: 15%; }
    .print_goods .transactions_columns .table_header .table_row > :nth-child(3) {
      width: 15%; }
    .print_goods .transactions_columns .table_header .table_row > :nth-child(4) {
      width: 12%; }
    .print_goods .transactions_columns .table_header .table_row > :nth-child(5) {
      width: 17%; }
    .print_goods .transactions_columns .table_header .table_row > :last-child {
      width: 17%; }
    .print_goods .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .print_goods .transactions_columns .table_body .table_row {
      height: auto; }
      .print_goods .transactions_columns .table_body .table_row > :first-child {
        width: 24%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .print_goods .transactions_columns .table_body .table_row > :nth-child(2) {
        width: 15%; }
      .print_goods .transactions_columns .table_body .table_row > :nth-child(3) {
        width: 15%; }
      .print_goods .transactions_columns .table_body .table_row > :nth-child(4) {
        width: 12%; }
      .print_goods .transactions_columns .table_body .table_row > :nth-child(5) {
        width: 17%; }
      .print_goods .transactions_columns .table_body .table_row > :last-child {
        width: 17%; }
      .print_goods .transactions_columns .table_body .table_row .row_item {
        font-size: 12px; }
    .print_goods .transactions_columns .table_row > :first-child {
      width: 24%;
      margin-right: 3px; }
    .print_goods .transactions_columns .table_row > :nth-child(2) {
      width: 15%; }
    .print_goods .transactions_columns .table_row > :nth-child(3) {
      width: 15%; }
    .print_goods .transactions_columns .table_row > :nth-child(4) {
      width: 12%; }
    .print_goods .transactions_columns .table_row > :nth-child(5) {
      width: 17%; }
    .print_goods .transactions_columns .table_row > :last-child {
      width: 17%; }
  .print_goods .table_container__summary {
    margin-top: 16px;
    float: right;
    font-size: 12px;
    width: 17%;
    color: #334150;
    text-align: start; }
  .print_goods__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    .print_goods__header--logo {
      width: auto;
      height: 40px; }
      .print_goods__header--logo img {
        width: 100%;
        height: 100%; }
    .print_goods__header--company {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500; }
  .print_goods__date {
    display: flex;
    justify-content: flex-end; }

.import_orders_inner > .title-block h1 {
  margin-top: 50px;
  margin-bottom: 30px; }

.import_orders_inner .tab_custom {
  padding-bottom: 10px; }
  .import_orders_inner .tab_custom button {
    margin-right: 34px; }

.import_orders_inner .wrapper_btn_table {
  display: flex;
  padding-top: 30px; }

.import_orders_inner .block_table .title_table {
  font-family: "Akrobat SemiBold", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 29px;
  padding-top: 40px;
  padding-bottom: 5px; }

.import_orders_inner .orders_inner_table .transactions_columns {
  width: 100%; }
  .import_orders_inner .orders_inner_table .transactions_columns .table_row {
    height: auto;
    padding: 10px 0;
    align-items: center;
    border-top: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row span {
      color: #7F8FA4;
      font-family: "Akrobat Light", sans-serif;
      font-size: 12px;
      padding-bottom: 4px; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :first-child {
      width: 15%; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :nth-child(2) {
      width: 15%; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :nth-child(3) {
      width: 18%; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :nth-child(4) {
      width: 12%; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :nth-child(5) {
      width: 14%; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :nth-child(6) {
      width: 12%; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > :last-child {
      width: 15%;
      justify-content: flex-end;
      display: flex; }
      .import_orders_inner .orders_inner_table .transactions_columns .table_row > :last-child button {
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
        color: #0253B3;
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        cursor: pointer; }
        .import_orders_inner .orders_inner_table .transactions_columns .table_row > :last-child button:hover {
          opacity: 0.7; }
    .import_orders_inner .orders_inner_table .transactions_columns .table_row > .row_item__buttons {
      flex-direction: column; }
      .import_orders_inner .orders_inner_table .transactions_columns .table_row > .row_item__buttons .button_moving {
        color: #D0021B;
        font-size: 13px;
        margin-top: 10px; }

.import_orders_inner .orders_inner_table .expensive-cost {
  color: #D0021B; }

.import_orders_inner .undistributed_goods {
  padding-top: 25px; }
  .import_orders_inner .undistributed_goods .transactions_columns {
    width: 100%; }
    .import_orders_inner .undistributed_goods .transactions_columns .table_row {
      height: 70px;
      border-top: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row span {
        color: #7F8FA4;
        font-family: "Akrobat Light", sans-serif;
        font-size: 12px;
        padding-bottom: 4px; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :first-child {
        width: 25%; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :nth-child(2) {
        width: 25%; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :nth-child(3) {
        width: 27%; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :nth-child(4) {
        width: 23%; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :nth-child(5) {
        width: 14%; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :nth-child(6) {
        width: 12%; }
      .import_orders_inner .undistributed_goods .transactions_columns .table_row > :last-child {
        width: 15%;
        justify-content: flex-end;
        display: block; }
        .import_orders_inner .undistributed_goods .transactions_columns .table_row > :last-child button {
          -webkit-transition: all 0.3s linear;
          transition: all 0.3s linear;
          color: #0253B3;
          font-size: 16px;
          line-height: 20px;
          background: transparent;
          cursor: pointer; }
          .import_orders_inner .undistributed_goods .transactions_columns .table_row > :last-child button:hover {
            opacity: 0.7; }

.change_supplier_title {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400; }

.block_table_wrapper .table_body {
  padding: 0 10px; }

.block_table_wrapper .table_row {
  border-top: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
  .block_table_wrapper .table_row > :first-child {
    width: 50%; }
  .block_table_wrapper .table_row > :nth-child(2) {
    width: 35%; }
  .block_table_wrapper .table_row > :nth-child(3) {
    width: 25%; }
  .block_table_wrapper .table_row .row_item {
    display: flex;
    cursor: pointer;
    flex-grow: 1;
    align-items: center; }
    .block_table_wrapper .table_row .row_item p {
      color: #01397C; }
    .block_table_wrapper .table_row .row_item span {
      margin-right: 10px;
      font-size: 12px;
      color: #7F8FA4; }

.block_table_wrapper p {
  height: 20px;
  color: #7F8FA4;
  font-size: 16px;
  line-height: 20px; }

.scroll_block {
  max-height: 400px;
  overflow: auto; }
  .scroll_block::-webkit-scrollbar {
    width: 4px; }
  .scroll_block::-webkit-scrollbar-track {
    border-radius: 2px;
    margin-right: 5px;
    background: #EDF2F8; }
  .scroll_block::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #01397C; }
  .scroll_block::-webkit-scrollbar-thumb:window-inactive {
    background: #01397C; }

.change_supplier_serach {
  width: 450px;
  margin: 30px 0; }
  .change_supplier_serach .search_product_wrapper input {
    width: calc(450px - 34px); }
  .change_supplier_serach .search_input_icon {
    line-height: 38px; }

.orders_inner_load {
  height: calc(100vh - 80px); }

.info-success-buy {
  display: flex;
  min-width: 420px;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 20px;
  position: fixed;
  left: 50%;
  bottom: 10%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #fff;
  z-index: 9999999;
  border-radius: 5px; }
  .info-success-buy > div {
    display: flex;
    flex-flow: row;
    align-items: center; }
    .info-success-buy > div > p {
      color: #334150;
      font-family: Akrobat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0; }
    .info-success-buy > div > span {
      margin-right: 5px; }
      .info-success-buy > div > span > img {
        margin-top: -2.5px; }
  .info-success-buy span.close {
    cursor: pointer; }

.page_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2%; }
  .page_not_found h1 {
    width: 80%;
    background-color: #FFFFFF;
    color: #334150;
    font-size: 60px;
    line-height: 100px;
    margin-bottom: 30px;
    padding-left: 30px;
    font-weight: 400; }

.auth_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 150px;
  background: #01397C;
  height: 80px; }
  .auth_header p {
    color: #ffffff;
    font-size: 14px;
    line-height: 13px;
    margin-right: 29px; }
  .auth_header .default_button_transparent {
    min-width: 147px;
    height: 41px;
    font-size: 14px;
    padding: 0; }

.logo_large {
  margin-bottom: 30px; }

.auth_container {
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 80px);
  background-color: #01397C;
  padding: 20px 150px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .auth_container form {
    width: 300px;
    position: relative; }
  .auth_container .custom_input_wrapper {
    margin-bottom: 20px; }
  .auth_container .default_button_wrapper {
    margin-top: 10px; }

.auth_bg {
  background: url(/static/media/auth_bg.3630f1b8.png) no-repeat 50% 50%;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 50px 70px 50px 100px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px; }

.auth_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }

.auth-box {
  width: 100%;
  height: 100%;
  display: flex; }
  .auth-box > :first-child {
    width: 50%; }
  .auth-box > :last-child {
    width: 50%;
    background-color: #ffffff; }

.auth-block_head {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 50px;
  position: relative; }
  .auth-block_head:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 1px;
    background-color: #0253B3; }

.auth_logo {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: -60px; }

.logo_center {
  margin-top: 120px; }

.auth_default_text {
  color: #A1AEB7;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 30px 0; }

.auth_link {
  color: #0253B3;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: 40px;
  position: absolute;
  bottom: 50px; }
  .auth_link:hover {
    text-decoration: underline; }

.auth_btn_wrapper {
  width: 300px;
  margin-top: 41px; }

.form_start {
  margin-top: -10vh; }

.auth_error {
  color: #ff0000;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: -24px;
  width: 100%; }

.auth-block_wrapper {
  width: 300px; }
  .auth-block_wrapper p {
    color: #01397C;
    font-size: 16px;
    line-height: 24px; }
  .auth-block_wrapper .default_button_wrapper {
    margin-top: 30px; }

