.refund_requests_table_wrapper{
  .transactions_table{
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
    }
    .transactions_columns {
      width: 100%;
      .table_header {
        .row_item {
          display: flex;
          align-items: center;
        }
      }
      .table_row {
        & > :first-child {
          width: 30%;
        }
        & > :nth-child(2) {
          width: 15%;
        }
        & > :nth-child(3) {
          width: 15%;
        }
        & > :nth-child(4) {
          width: 17%;
        }
        & > :nth-child(5) {
          width: 17%;
        }
        & > :nth-child(6) {
          width: 16%;
        }
        & > :nth-child(7) {
          width: 17%;
        }
        & > :nth-child(8) {
          width: 10%;
        }
        & > :last-child {
          width: 16%;
        }
      }
    }
  }
  .control_panel {
    display: flex;
    padding: 30px 0 0 0;
  }
}