.transactions_table_wrapper{
  .transactions_table{
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
    }
    .transactions_columns {
      width: 100%;
      .table_header {
        .row_item {
          display: flex;
          align-items: center;
        }
      }
      .table_row {
        & > :first-child {
          width: 21%;
        }
        & > :nth-child(2) {
          width: 21%;
        }
        & > :nth-child(3) {
          width: 21%;
        }
        & > :nth-child(4) {
          width: 21%;
        }
        & > :last-child {
          width: 16%;
        }
      }
    }
  }

}



